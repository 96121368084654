import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BlogService } from '../core/services/blog.service';
import { VendorDetailResponseInterface } from '../cart.service';
import { Vendor, FAQ } from '../core/model/store-collection.model';
import { UiService } from '../ui.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '../form.service';
import { StoreListService } from '../core/services/store.service';
import { environment } from '../../environments/environment.prod';
import {
  StoreBanner,
  StoreBannerResponse,
} from '../core/model/store-banner.model';
import { StoreCuisine } from './store-cuisines.model';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss'],
})
export class StoreDetailsComponent implements OnInit {
  vendorDetail: Vendor = {} as Vendor;
  faqs: FAQ[] = [];
  safeUrl: SafeResourceUrl;
  isModalOpen: boolean = false;
  enquiryFormGroup: FormGroup;
  banners: StoreBanner[] = [];
  baseUrl = environment.base_url;
  storeName!: string;
  storeCode!: string;
  vendorId!: string;
  storeCuisines: StoreCuisine[] = [];
  currentPath: any;
  constructor(
    private modalController: ModalController,
    private _route: ActivatedRoute,
    public _blogService: BlogService,
    public _storeService: StoreListService,
    private _router: Router,
    private _uiService: UiService,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private sanitizer: DomSanitizer
  ) {
    _route.params.subscribe((res) => {
      if (res['storeName']) {
        this.storeName = res['storeName'];
      }
      if (res['storeCode']) {
        this.storeCode = res['storeCode'];
      }
      this.getDetails(this.storeCode, 0, 0);
    });

    // FOR GET URL
    this.currentPath = this._router.url;
  }

  ngOnInit() {
    this.buildForm();
    this.getFaqs();
  }
  buildForm() {
    this.enquiryFormGroup = this._formBuilder.group({
      vendorName: new FormControl(null, [Validators.required]),
      vendorEmail: new FormControl(null, [Validators.required]),
      requestType: new FormControl('CLAIM_LISTING', [Validators.required]),
      status: new FormControl('NEW', [Validators.required]),
      vendorMobile: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      remark: new FormControl(''),
    });
  }

  private toQueryString(params: { [key: string]: any }): string {
    return Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');
  }
  getDetails(code, tableNumber, diningAreaId) {
    this._blogService
      .fetchStoreDetail({
        vendorCode: code,
        tableNumber: tableNumber,
        diningAreaId: diningAreaId,
      })
      .then((res: any) => {
        this.vendorDetail = res.data;
        this.getBanners(this.vendorDetail.VendorIdPK);
        this.setGoogleMapUrl();
        this.onFetchStoreCuisines();
      });
  }
  onFetchStoreCuisines() {
    this._storeService
      .fetchStoreCuisines({
        VendorIdFK: this.vendorDetail.VendorIdPK,
      })
      .subscribe({
        next: (res) => {
          console.log(res.data);
          this.storeCuisines = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }
  getFaqs() {
    // let route
    // if (this.currentPath.includes('store/details')) {
    //   route = store/details
    // }

    this._blogService
      .fetchFaqs({
        route: 'store/details',
      })
      .subscribe({
        next: (res: any) => {
          this.faqs = res.data;
        },
        error: (err) => {
          console.error('Error fetching FAQs:', err);
        },
      });
  }

  setGoogleMapUrl() {
    // const mapUrl = `https://www.google.com/maps/embed/v1/view?key=AIzaSyBbZae0873NPLTC68CqPQmKWKBFGFHPIf8&center=${this.vendorDetail.vendorLat},${this.vendorDetail.vendorLang}&zoom=14`;
    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyBbZae0873NPLTC68CqPQmKWKBFGFHPIf8&q=${this.vendorDetail.vendorLat},${this.vendorDetail.vendorLang}&zoom=14`;
    // const mapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d${this.vendorDetail.vendorLang}!3d${this.vendorDetail.vendorLat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl); // Sanitize the URL
  }

  getBanners(vendorId) {
    this._storeService
      .fetchPromotionalBanner({
        vendorId: vendorId,
        location: 'WEBSITE',
      })
      .subscribe((res: StoreBannerResponse) => {
        console.log(res);
        this.banners = res.data;
      });
  }
  onCreate() {
    this._formService.markFormAsTouched(this.enquiryFormGroup);
    this.enquiryFormGroup.value.storeType = this.vendorDetail.vendorStoreType;
    this._blogService.createEnquiry(this.enquiryFormGroup.value).subscribe({
      next: (res) => {
        this._uiService.presentToast('Enquiry created successfully.');
        this.onClose();
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }
  onImageLoadError(e: any) {
    const target = event.target as HTMLImageElement;
    target.src = '../../assets/images/dummy/1.jpeg';
    // e.target.src = '../../assets/images/dummy/1.jpeg';
  }
  openModal() {
    this.isModalOpen = true;
  }
  onClose() {
    this.isModalOpen = false;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
