import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './auth.interceptor.service';
import { CategoryMenuPopoverComponent } from './category-menu-popover/category-menu-popover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderStatusPage } from './order/order-status/order-status.page';
import { OrderPreferenceModalComponent } from './order-preference-modal/order-preference-modal.component';
import { CartPage } from './cart/cart.page';
import { StorePage } from './store/store.page';
import { OrderPage } from './order/order.page';
import { OopsPage } from './oops/oops.page';
import { ActivePage } from './order/active/active.page';
import { PastPage } from './order/past/past.page';
import { OffersPage } from './offers/offers.page';
import { WelcomeScreenPage } from './welcome-screen/welcome-screen.page';
import { AuthPage } from './auth/auth.page';
import { AllStoresPage } from './all-stores/all-stores.page';
import { LandingPage } from './landing/landing.page';
import { NoInternetPage } from './no-internet/no-internet.page';
import { UserAccountPage } from './user-account/user-account.page';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ProfilePage } from './profile/profile.page';
import { NotificationsPage } from './notifications/notifications.page';
import { SharedModule } from './shared/shared.module';
import { ItemCustomizationModalComponent } from './shared/components/item-customization-modal/item-customization-modal.component';
import { RepeatLastModalPage } from './shared/components/repeat-last-modal/repeat-last-modal.page';
import { CategoryFragmentComponent } from './shared/components/category-fragment/category-fragment.component';
import { InvoicePage } from './invoice/invoice.page';
import { CouponsPage } from './coupons/coupons.page';
import { WebLoginPage } from './auth/web-login/web-login.page';
import { AskCustomerInfoPage } from './cart/ask-customer-info/ask-customer-info.page';
import { HomePage } from './home/home.page';
import { UserAddressesPage } from './user-addresses/user-addresses.page';
import { CreateAddressComponent } from './create-address/create-address.component';
import { DeleteAccountPage } from './delete-account/delete-account.page';
import { BlogListingPage } from './blog-listing/blog-listing.page';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { WebHeaderComponent } from './shared/components/web-header/web-header.component';
import { WebFooterComponent } from './shared/components/web-footer/web-footer.component';
import { StoreDetailsComponent } from './store-details/store-details.component';
import { StoreCardComponent } from './shared/components/store-card/store-card.component';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { QuickLinksComponent } from './shared/components/quicklinks/quick-links.component';
import { VendorOnboardingPage } from './vendor-onboarding/vendor-onboarding.page';
import { ShareButton } from 'ngx-sharebuttons/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MarkdownModule } from 'ngx-markdown';
import {
  provideShareButtonsOptions,
  SharerMethods,
  withConfig,
} from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { InstaPostsComponent } from './shared/components/insta-posts/insta-posts.component';

@NgModule({
  declarations: [
    AppComponent,
    CategoryMenuPopoverComponent,
    OrderPreferenceModalComponent,
    CartPage,
    StorePage,
    OrderPage,
    OopsPage,
    ActivePage,
    PastPage,
    OrderStatusPage,
    OffersPage,
    WelcomeScreenPage,
    AuthPage,
    AllStoresPage,
    LandingPage,
    NoInternetPage,
    UserAccountPage,
    ProfilePage,
    NotificationsPage,
    ItemCustomizationModalComponent,
    RepeatLastModalPage,
    CategoryFragmentComponent,
    InvoicePage,
    CouponsPage,
    WebLoginPage,
    AskCustomerInfoPage,
    HomePage,
    UserAddressesPage,
    CreateAddressComponent,
    DeleteAccountPage,
    BlogListingPage,
    BlogDetailsComponent,
    WebHeaderComponent,
    WebFooterComponent,
    StoreDetailsComponent,
    StoreCardComponent,
    QuickLinksComponent,
    VendorOnboardingPage,
    InstaPostsComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({}),
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ShareButtons,
    FontAwesomeModule,
    MarkdownModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Title,
    provideShareButtonsOptions(
      shareIcons(),
      withConfig({
        debug: true,
        sharerMethod: SharerMethods.Anchor,
      })
    ),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
