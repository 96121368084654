import { Component, OnInit } from '@angular/core';
import { BlogService } from '../core/services/blog.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Article, Category, FeaturedData } from '../core/model/blog.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-blog-listing',
  templateUrl: './blog-listing.page.html',
  styleUrls: ['./blog-listing.page.scss'],
})
export class BlogListingPage implements OnInit {
  categoryData: Category[] = [];
  article: Article[] = [];
  featuredData: FeaturedData[] = [];
  categoryId: any = null;
  searchText: string = '';
  searchFormControl = new FormControl(null);
  strapiImageBaseUrl = environment.strapiImageBaseUrl;

  constructor(public _blogService: BlogService, private _router: Router) {}

  ngOnInit() {
    this.onFetch();
    this.onFetchBlog();
    this.onSearch();
    this.onFetchFeaturedBlog();
  }
  onSearch() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res: any) => {
          this.searchText = res;
          this.onFetchBlog();
        })
      )
      .subscribe();
  }
  onChangeCategory(ev: any) {
    if (ev.value === 'all') {
      this.categoryId = null;
    } else {
      this.categoryId = ev.value;
    }
    this.searchFormControl.reset();
    this.onFetchBlog();
  }
  onFetch() {
    this._blogService
      .fetchCategoryData({ populate: '*' })
      .then((res) => {
        this.categoryData = res.data;
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }
  formatContent(content: string): string {
    let formattedContent = content.replace(/\n\n/g, '<br><br>');

    const words = formattedContent.split(/\s+/);
    if (words.length > 70) {
      formattedContent = words.slice(0, 40).join(' ') + ' ...';
    }

    return formattedContent;
  }

  onFetchBlog() {
    const filterData: any = {
      populate: '*',
      ...(this.searchText && { searchText: this.searchText }),
    };

    if (this.categoryId) {
      filterData.filters = {
        category: { id: this.categoryId },
      };
    }

    this._blogService
      .fetchBlogDetails(filterData)
      .then((res) => {
        this.article = res.data;
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }
  async onDetailsPage(data: Article) {
    this._router.navigate(['/blog/details', data.slug], {
      replaceUrl: false,
    });
  }

  onFetchFeaturedBlog() {
    const filterData: any = {
      populate: '*',
      filters: {
        isFeatured: {
          $eq: true,
        },
      },
    };
    this._blogService
      .fetchBlogDetails(filterData)
      .then((res) => {
        this.featuredData = res.data;
        console.log(this.featuredData);
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }
}
