// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection {
  padding: 16px 64px;
}
@media screen and (max-width: 768px) {
  .collection {
    padding: 16px 24px;
  }
}
.collection__title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.collection__subtitle {
  margin-bottom: 8px;
}
.collection__item {
  padding: 8px;
}
.collection__item--media {
  border-radius: 8px;
  overflow: hidden;
  height: 200px;
  margin-bottom: 8px;
}
.collection__item--media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.collection__item--info-name {
  font-weight: 600;
}
.collection__item--info-location {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/store-card/store-card.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EAFJ;IAGM,kBAAA;EAGJ;AACF;AAFI;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAIN;AAFI;EACE,kBAAA;AAIN;AAAI;EACE,YAAA;AAEN;AADM;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;AAGR;AAFQ;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAIV;AAAQ;EACE,gBAAA;AAEV;AAAQ;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,QAAA;AAEV","sourcesContent":[".collection {\n    padding: 16px 64px;\n    @media screen and (max-width: 768px) {\n      padding: 16px 24px;\n    }\n    &__title {\n      font-size: 20px;\n      font-weight: 600;\n      margin-bottom: 8px;\n    }\n    &__subtitle {\n      margin-bottom: 8px;\n    }\n    &__list {\n    }\n    &__item {\n      padding: 8px;\n      &--media {\n        border-radius: 8px;\n        overflow: hidden;\n        height: 200px;\n        margin-bottom: 8px;\n        img {\n          width: 100%;\n          height: 100%;\n          object-fit: cover;\n        }\n      }\n      &--info {\n        &-name {\n          font-weight: 600;\n        }\n        &-location {\n          display: flex;\n          align-items: center;\n          font-size: 14px;\n          gap: 2px;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
