import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StoreListService } from '../core/services/store.service';
import { UiService } from '../ui.service';
import { Stores } from '../core/model/all-store.modal';
import { environment } from 'src/environments/environment.prod';
import { Vendor } from '../core/model/store-collection.model';

@Component({
  selector: 'app-all-stores',
  templateUrl: './all-stores.page.html',
  styleUrls: ['./all-stores.page.scss'],
})
export class AllStoresPage implements OnInit {
  allStoresData: Stores[] = [];
  showSearchBar = false;
  baseUrl = environment.base_url;
  city: string;
  state: string;
  area: string;
  areaId: any;
  cityId: number;
  isFocusMode: boolean = false;
  constructor(
    private _router: Router,
    public modalController: ModalController,
    private _store: StoreListService,
    private _uiService: UiService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      console.log(params);
      
      const AreaAndCityName = params['areaName-:cityName'];
      if (AreaAndCityName) {
        let items = AreaAndCityName.split('-');
        // let items = AreaAndCityName.lastIndexOf('-');
        console.log(items);

        if (items[0] !== 'null') {
          this.area = items[0];
        }
        if (items[1] !== 'null') {
          this.city = items[1];
        }
      }
      if (params['areaId']) {
        this.areaId = params['areaId'];
      }
      // } else {
      //   this.areaId = null;
      // }
      if (params['cityName']) {
        this.city = params['cityName'];
      }
      // } else {
      //   this.city = null;
      // }
      if (params['cityId']) {
        this.cityId = params['cityId'];
      }
      // } else {
      //   this.cityId = null;
      // }

      // let area = params['areaName'];
      // if (area !== 'null') {
      //   this.area = area;
      // }
      // const cityAndAreaId = params['cityName_:areaId_:cityId'];
      // let items = cityAndAreaId.split('_');

      // if (items[0] !== 'null') {
      //   this.city = items[0];
      // }
      // if (items[1] !== 'null') {
      //   this.areaId = items[1];
      // }
      // if (items[2] !== 'null') {
      //   this.cityId = items[2];
      // }
      this.onGet();
    });
    this.onGet();
  }

  onFocusLocation() {
    this.isFocusMode = true;
    setTimeout(() => {
      this.isFocusMode = false;
    }, 2000);
  }
  openStore() {
    // this._router.navigateByUrl('/store');
  }

  toggleSearchBar(e: any) {
    this.showSearchBar = !this.showSearchBar;
  }
  onImageLoadError(e: any) {
    e.target.src = '../../assets/icons/store.svg';
  }
  onGet() {
    this._store
      .fetchStore({
        vendorStatus: 'ACTIVE',
        currentPage: 1,
        itemsPerPage: 100,
        searchData: '',
        expirationDateStart: '',
        expirationDateEnd: '',
        ...(this.areaId && { AreaIdFK: this.areaId }),
        ...(this.cityId && !this.areaId && { CityIdFK: this.cityId }),
      })
      .subscribe({
        next: (res) => {
          this.allStoresData = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }

  async openScanner() {
    // const modal = await this.modalController.create({
    //   component: ScannerComponent,
    //   cssClass: "scanner-modal",
    // });
    // return await modal.present();
  }
  async onStoreDetailsPage(data: Stores) {
    const storeName = data.vendorStoreName.replace(/\s+/g, '-');
    const url = `/store/details/${storeName}/${data.vendorCode}`;
    this._router.navigateByUrl(url);
  }
}
