import { Component, OnInit } from '@angular/core';
import {
  CartPageData,
  CartService,
  UpSellCartItems,
  VendorDetailResponseInterface,
} from '../cart.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import {
  ModalController,
  AlertController,
  ToastController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';
import { OrderPreferenceModalComponent } from '../order-preference-modal/order-preference-modal.component';
import { UiService } from '../ui.service';
import { VENDOR_PREFERENCES } from '../shared/constants/app.constant';
import { FormControl } from '@angular/forms';
import { CouponInterface, CouponsPage } from '../coupons/coupons.page';
import { AskCustomerInfoPage } from './ask-customer-info/ask-customer-info.page';
import { CreateAddressComponent } from '../create-address/create-address.component';
import {
  UserAddresses,
  UserAddressesService,
} from '../user-addresses/user-addresses.service';
import { BannerInterface } from '../core/model/banner.model';
import { BannerService } from '../core/services/banner.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
  providers: [UserAddressesService],
})
export class CartPage implements OnInit {
  fetchCartObservable: Observable<any>;
  placeOrderObservable: Observable<any>;
  updateUserStatusObservable: Observable<any>;
  updateUserNumberObs: Observable<any>;
  upSellCart$: Observable<UpSellCartItems[]>;
  cartDetails: CartPageData[] = [];
  localCardData;
  totalPrice = 0;
  modal;
  isCartEmpty = false;
  processing = false;
  // currentOtp;
  // otpTimer;
  alertUserOtpConfirmation;
  // otpTimerString = '';
  subscriptionModalDismiss: Subscription;
  baseUrl = environment.base_url;
  storageData: StorageInterface;
  vendorDetail: VendorDetailResponseInterface;
  isScheduleOrderAvailable = false;
  selectedTimeSlot;
  orderType = 'Instant';
  isRecommendationFucntional = false;
  vendorPreferences;
  upSellItems: UpSellCartItems[];
  recommendedItems: UpSellCartItems[];
  cartCount = 0;
  initiatePaymentFC = new FormControl(false);
  appliedCoupon: CouponInterface;
  discountPriceValue = 0;
  homeDeliveryFC = new FormControl(false);
  addresses: UserAddresses[] = [] as UserAddresses[];
  banner: BannerInterface[] = [];
  slideOpts = {
    initialSlide: 0,
    speed: 250,
    autoplay: true,
  };
  constructor(
    private cartService: CartService,
    private router: Router,
    private storageService: StorageService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    public modalController: ModalController,
    private _uiService: UiService,
    private _userAddress: UserAddressesService,
    private _bannerService: BannerService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }

  decreaseQty(data, i: number) {
    data.qty = data.qty - 1;
    this.cartService.decreaseQtyFromCart(data.parentItemIdPK, data.cartId);
    if (data.qty <= 0) {
      this.cartDetails.splice(i, 1);
    }
    this.setCart();
  }

  increaseQty(data) {
    data.qty = data.qty + 1;
    this.cartService.increaseQtyFromCart(data.parentItemIdPK, data.cartId);

    this.setCart();
  }

  async setStorageData() {
    this.storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.fetchBanner();
      this.setCart();
    });
  }
  async fetchBanner() {
    this._bannerService
      .fetchBanner({ vendorId: this.storageData?.VendorKey })
      .subscribe({
        next: (res) => {
          this.banner.push(
            ...res.data.filter((banner) => banner.location === 'CART')
          );
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }
  getVendorDetails() {
    this.cartService
      .fetchVendorDetail(
        this.storageData.VendorStoreKey,
        this.storageData.CurrentTableNumber,
        this.storageData.DiningAreaId
      )
      .subscribe((res: VendorDetailResponseInterface) => {
        this.vendorDetail = res;
        console.log('VENDO PREF: ', this.vendorDetail.data.preferences);

        this.vendorPreferences = JSON.parse(this.vendorDetail.data.preferences);
        if (
          this.vendorPreferences.hasOwnProperty(
            VENDOR_PREFERENCES.StoreIsScheduleOrderAvailable
          )
        ) {
          if (this.vendorPreferences.StoreIsScheduleOrderAvailable === 'YES') {
            this.isScheduleOrderAvailable = true;
          }
        }

        // check for upsell cart
        if (
          this.vendorPreferences.hasOwnProperty(
            VENDOR_PREFERENCES.StoreIsUpsellCartAvailable
          )
        ) {
          if (this.vendorPreferences.StoreIsUpsellCartAvailable === 'YES') {
            this.setUpsellCart();
          }
        }
      });
  }

  setCart() {
    this.cartService.setCartData();
    this.localCardData = this.cartService.getFullCartData();
    if (this.localCardData.length !== 0) {
      this.isCartEmpty = false;
      this.fetchCartObservable = this.cartService.fetchCartDetail();
      this.fetchCartObservable.subscribe(
        (responseData) => {
          this.cartDetails = responseData;
          console.log('cartDetails', responseData);

          this.totalPrice = this.cartService.getCartTotalPriceForCart(
            this.cartDetails
          );
          this.cartCount = this.cartService.getCartLength();
          this.appliedCoupon = null;
          this.discountPriceValue = 0;
          this.getVendorDetails();
        },
        (error) => {
          this._uiService.presentToast(error);
        }
      );
    } else {
      this.isCartEmpty = true;
    }
  }
  ngOnDestroy() {
    // this.subscriptionModalDismiss.unsubscribe();
  }

  placeOrder() {
    if (this.storageData.UserMobileKey === '9999999999') {
      this.openAskCustomerInfo();
      return;
    }
    if (this.orderType === 'Schedule') {
      this.onPlaceOrder();
      return;
    }

    // check for running order available or not
    if (
      this.vendorPreferences &&
      this.vendorPreferences.hasOwnProperty(
        VENDOR_PREFERENCES.StoreIsRunningOrderAvailable
      )
    ) {
      if (this.vendorPreferences.StoreIsRunningOrderAvailable === 'YES') {
        this.cartService
          .checkRunningOrderStatus(
            this.storageData.CustomerKey,
            this.orderType.toUpperCase(),
            this.vendorDetail.data.VendorIdPK
          )
          .subscribe((responseData: any) => {
            if (responseData.statusCode === 200) {
              if (responseData.orderPlace === true) {
                this.onPlaceOrder();
              } else {
                this.addOrderInPreviousOrder(responseData.OrderIdPK);
              }
            }
          });
        return;
      }
    }
    this.onPlaceOrder();
  }

  onPlaceOrder() {
    this.processing = true;
    let data = [];
    let tempObj;
    let i = 0;
    this.cartDetails.forEach((element) => {
      tempObj = {
        CategoryIdFK: element.CategoryIdFK,
        ItemIdFK: element.ItemIdPK,
        quantity: element.qty,
        price: element.cartPrice,
        status: element.InventoryItemMasterIdFK ? 'PREPARED' : 'WAITING',
        addOnDetails: JSON.stringify(element.addOnDetails),
        ...(element.InventoryItemMasterIdFK && {
          InventoryItemMasterIdFK: element.InventoryItemMasterIdFK,
        }),
      };

      data[i] = tempObj;
      i++;
    });
    let address = null;
    if (this.homeDeliveryFC.value) {
      address = JSON.stringify({
        location: this.getSelectedAddress().location,
        latitude: this.getSelectedAddress().latitude,
        longitude: this.getSelectedAddress().longitude,
        landmark: this.getSelectedAddress().landmark,
        area: this.getSelectedAddress().area,
        city: this.getSelectedAddress().city.cityName,
      });
    }
    this.placeOrderObservable = this.cartService.placeOrder(
      this.totalPrice,
      data,
      this.storageData.CustomerKey,
      this.storageData.VendorKey,
      this.storageData.CurrentTablePK,
      this.orderType === 'Schedule'
        ? this.selectedTimeSlot.split('T')[0] +
            ' ' +
            this.selectedTimeSlot.split('T')[1].split(':')[0] +
            ':' +
            this.selectedTimeSlot.split('T')[1].split(':')[1] +
            ':00'
        : null,
      this.discountPriceValue,
      address,
      address ? 'HOME_DELIVERY' : 'BY_CUSTOMER'
    );

    this.placeOrderObservable.subscribe(
      (responseData) => {
        if (responseData.body.statusCode === 200) {
          this.processing = false;
          this.storageService.clearCartFromStorage();
          this.cartDetails = [];
          this.isCartEmpty = true;
          this.showErrorToast('Order Placed');
          if (this.initiatePaymentFC.value) {
            this.initiatePayment();
          }
          this.router.navigateByUrl('/order');
        }
      },
      (error) => {
        this.showErrorToast(error);
        this.processing = false;
      }
    );
  }

  async showErrorToast(data: any) {
    let toast = await this.toastCtrl.create({
      message: data,
      duration: 3000,
      position: 'top',
    });

    toast.present();
  }

  backToMenu() {
    this.router.navigate(['/store']);
  }

  async addOrderInPreviousOrder(OrderIdPK) {
    this.alertUserOtpConfirmation = await this.alertController.create({
      header: 'Info!',
      message:
        'You already have a running order, current order will append to running order. Do you like to continue?',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.onUpdateOrder(OrderIdPK);
          },
        },
        'No',
      ],
    });

    await this.alertUserOtpConfirmation.present();
  }

  onUpdateOrder(OrderIdPK) {
    this.processing = true;
    let data = [];
    let tempObj;
    let i = 0;
    this.cartDetails.forEach((element) => {
      tempObj = {
        OrderIdFK: OrderIdPK,
        CategoryIdFK: element.CategoryIdFK,
        ItemIdFK: element.ItemIdPK,
        quantity: element.qty,
        price: element.cartPrice,
        status: 'WAITING',
        addOnDetails: JSON.stringify(element.addOnDetails),
      };

      data[i] = tempObj;
      i++;
    });
    this.placeOrderObservable = this.cartService.updateOrder(
      this.totalPrice,
      data
    );

    this.placeOrderObservable.subscribe(
      (responseData) => {
        if (responseData.body.statusCode === 200) {
          // this.socketService.connectUser();
          // this.socketService.sendMessage(responseData.body.data);
          this.processing = false;
          this.storageService.clearCartFromStorage();
          this.cartDetails = [];
          this.isCartEmpty = true;
          this.showErrorToast('Order Updated!');
          this.router.navigate(['/order']);
        }
      },
      (error) => {
        this.showErrorToast('Server side error, Try Again!');
        this.processing = false;
      }
    );
  }

  async openPreferenceModal() {
    let storeTiming = JSON.parse(this.vendorDetail.data.storeTiming);
    let startTime;
    let endTime;
    storeTiming.forEach((timing) => {
      if (
        timing.day ===
        new Date().toLocaleDateString('en-IN', { weekday: 'long' })
      ) {
        startTime = timing.start;
        endTime = timing.end;
      }
    });

    const modal = await this.modalController.create({
      component: OrderPreferenceModalComponent,
      cssClass: 'order-preference-modal',
      componentProps: {
        min: startTime,
        max: endTime,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.selectedTimeSlot = data.data.scheduleTime;
        this.orderType = data.data.orderType;
      }
    });
    return await modal.present();
  }

  async openCoupons() {
    const modal = await this.modalController.create({
      component: CouponsPage,
      cssClass: 'order-preference-modal',
      componentProps: {
        vendorId: this.vendorDetail.data.VendorIdPK,
        cartValue: this.totalPrice,
      },
    });

    modal.onDidDismiss().then((res) => {
      console.log(res);
      if (res.data) {
        this.appliedCoupon = res.data.coupon;
        this.onApplyCouponOnCartValue();
      } else {
        this.appliedCoupon = null;
        this.totalPrice = this.cartService.getCartTotalPriceForCart(
          this.cartDetails
        );
      }
    });
    return await modal.present();
  }

  onApplyCouponOnCartValue() {
    if (this.appliedCoupon.discountType === 'PERCENTAGE') {
      this.discountPriceValue = +(
        this.totalPrice *
        (+this.appliedCoupon.discount / 100)
      ).toFixed(0);
    } else {
      this.discountPriceValue = this.appliedCoupon.discount;
    }

    if (this.discountPriceValue > this.appliedCoupon.maximumAllowedDiscount) {
      this.discountPriceValue = this.appliedCoupon.maximumAllowedDiscount;
    }

    this.totalPrice -= this.discountPriceValue;
  }

  removeCoupon() {
    this.appliedCoupon = null;
    this.discountPriceValue = 0;
    this.totalPrice = this.cartService.getCartTotalPriceForCart(
      this.cartDetails
    );
  }

  onImageLoadError(e: any) {
    e.target.src = environment.base_url + 'images/defaultitem.png';
  }

  addRecommendedItemToCart(item: UpSellCartItems) {
    if (item.variantIds) {
      // open customization modal
    } else {
      this.cartService.updateCartData({
        ItemIdPK: item.ItemIdPK,
        qty: 1,
        price: item.price,
        recommendedIds: item.recommendedIds,
        upSellItem: true,
      });
      this.totalPrice = this.cartService.getCartTotalPriceForCart(
        this.cartDetails
      );
      this.setCart();
      item.isInCart = true;
    }
  }

  setUpsellCart() {
    this.upSellItems = [];
    this.recommendedItems = [];
    this.upSellCart$ = this.cartService.prepareUpsellCart(
      this.totalPrice,
      this.cartDetails,
      this.vendorDetail.data.VendorIdPK
    );
    if (!this.upSellCart$) {
      return;
    }
    this.upSellCart$.subscribe((res) => {
      if (res) {
        console.log('upsell-items', res);
        res.forEach((item, i) => {
          if (!item.isInCart) {
            // actual price can only for upsell items
            if (item.actualPrice) {
              this.upSellItems.push(item);
            } else {
              this.recommendedItems.push(item);
            }
          }
        });
      }
    });
  }
  initiatePayment() {
    window.open(`${this.storageData.VendorUpiQR}&am=${this.totalPrice}`);
  }

  async openAskCustomerInfo() {
    const modal = await this.modalController.create({
      component: AskCustomerInfoPage,
      cssClass: 'customer-details-modal',
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.storageData.UserMobileKey = data.data.mobileNumber;
        this.storageData.CustomerKey = data.data.CustomerIdPk;
        this.storageData.CustomerNameKey = data.data.name;
        this.storageService
          .setObject(StorageEnum.UserData, this.storageData)
          .then((res) => {
            this.placeOrder();
          });
      }
    });
    await modal.present();
  }

  async openAddressModal() {
    const modal = await this.modalController.create({
      component: CreateAddressComponent,
      initialBreakpoint: 0.5,
      breakpoints: [0.5, 0.75, 0.9],
    });
    modal.present();

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        this.fetchAddresses();
      }
    });
  }

  onChangeDeliveryPreference(event: any) {
    if (event.detail.checked && this.addresses.length === 0) {
      this.fetchAddresses();
    }
  }

  fetchAddresses() {
    this._userAddress.fetchAddresses().subscribe({
      next: (res) => {
        this.addresses = res.data;
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onSelectAddress(i: number) {
    this.addresses.forEach((address) => {
      address.isSelected = false;
    });

    this.addresses[i].isSelected = true;
  }

  getSelectedAddress(): UserAddresses {
    return this.addresses.find((address) => {
      return address.isSelected;
    });
  }
}
