// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list {
  height: 100%;
  max-height: 400px !important;
  overflow-y: scroll;
  border-radius: 10px;
  background: var(--ion-color-dark);
}
ion-list ion-item {
  --background: var(--ion-color-dark);
  --color: var(--ion-color-dark-contrast);
}
ion-list ion-item ion-icon {
  padding-right: 10px;
}
ion-list ion-item ion-label {
  font-size: 0.8em;
}

.fab_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
  width: 120px;
  bottom: 0px;
  left: calc(50% - 60px);
  padding: 12px 24px;
  border-radius: 16px;
  margin: 20px auto 0;
}
.fab_wrapper span {
  font-size: 0.8em;
}
.fab_wrapper ion-icon {
  font-size: 20px;
  padding-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/category-menu-popover/category-menu-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,mBAAA;EACA,iCAAA;AACF;AACE;EACE,mCAAA;EACA,uCAAA;AACJ;AAAI;EACE,mBAAA;AAEN;AAAI;EACE,gBAAA;AAEN;;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iCAAA;EACA,qCAAA;EAEA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EAEA,mBAAA;AAFF;AAGE;EACE,gBAAA;AADJ;AAGE;EACE,eAAA;EACA,kBAAA;AADJ","sourcesContent":["ion-list {\n  height: 100%;\n  max-height: 400px !important;\n  overflow-y: scroll;\n  border-radius: 10px;\n  background: var(--ion-color-dark);\n\n  ion-item {\n    --background: var(--ion-color-dark);\n    --color: var(--ion-color-dark-contrast);\n    ion-icon {\n      padding-right: 10px;\n    }\n    ion-label {\n      font-size: 0.8em;\n    }\n  }\n}\n\n.fab_wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: var(--ion-color-dark);\n  color: var(--ion-color-dark-contrast);\n  // position: fixed;\n  width: 120px;\n  bottom: 0px;\n  left: calc(50% - 60px);\n  padding: 12px 24px;\n  border-radius: 16px;\n  // margin-top: 20px;\n  margin: 20px auto 0;\n  span {\n    font-size: 0.8em;\n  }\n  ion-icon {\n    font-size: 20px;\n    padding-right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
