import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PlatformCheckGuard implements CanActivate {
  constructor(private platform: Platform, private router: Router) {}

  canActivate(): boolean {
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      this.router.navigate(['/welcome-screen']);
    } else {
      this.router.navigate(['/home']);
    }
    return false;
  }
}
