// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top_action_bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);
}
.top_action_bar_container {
  display: flex;
  padding: 12px 20px;
}
.top_action_bar .search_icon_wrapper,
.top_action_bar .scan_icon_wrapper {
  background-color: #fff;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.top_action_bar .search_icon_wrapper img,
.top_action_bar .scan_icon_wrapper img {
  width: 20px;
}
.top_action_bar .brand_name {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  color: var(--ion-color-dark);
}

main {
  display: flex;
  justify-content: center;
  height: 100%;
}

.time-popover {
  margin-top: -60px;
}

ion-list {
  width: 100%;
}

ion-datetime {
  --background: #fff;
}

ion-footer {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/order-preference-modal/order-preference-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,gBAAA;EACA,UAAA;EACA,wCAAA;AACF;AACE;EACE,aAAA;EACA,kBAAA;AACJ;AAEE;;EAEE,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AACI;;EACE,WAAA;AAEN;AAEE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,4BAAA;AAAJ;;AAIA;EACE,aAAA;EACA,uBAAA;EAGA,YAAA;AAHF;;AAMA;EACE,iBAAA;AAHF;;AAMA;EACE,WAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;;AAKA;EACE,aAAA;AAFF","sourcesContent":[".top_action_bar {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  background: #fff;\n  z-index: 9;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);\n\n  &_container {\n    display: flex;\n    padding: 12px 20px;\n  }\n\n  .search_icon_wrapper,\n  .scan_icon_wrapper {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 6px;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    img {\n      width: 20px;\n    }\n  }\n\n  .brand_name {\n    font-size: 16px;\n    letter-spacing: 2px;\n    font-weight: 500;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-left: 12px;\n    color: var(--ion-color-dark);\n  }\n}\n\nmain {\n  display: flex;\n  justify-content: center;\n  // flex-direction: column;\n  // justify-content: flex-start;\n  height: 100%;\n}\n\n.time-popover {\n  margin-top: -60px;\n}\n\nion-list {\n  width: 100%;\n}\n\nion-datetime {\n  --background: #fff;\n}\nion-footer {\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
