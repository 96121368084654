// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo-src {
  font-size: 1.5em;
  font-weight: 500;
  color: var(--ion-color-primary);
  letter-spacing: 2px;
  padding: 16px 8px;
}
.header__logo-src span {
  color: rgb(248, 179, 48);
}

.container {
  background: #f1f4f6;
  padding: 16px 32px 80px;
  overflow-y: scroll;
}

strong {
  font-size: 1.25rem;
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/app/static-pages/privacy/privacy.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACI;EACE,gBAAA;EACA,gBAAA;EACA,+BAAA;EACA,mBAAA;EACA,iBAAA;AACN;AAAM;EACE,wBAAA;AAER;;AAGA;EACE,mBAAA;EACA,uBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,mBAAA;AAAF","sourcesContent":[".header {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &__logo {\n    &-src {\n      font-size: 1.5em;\n      font-weight: 500;\n      color: var(--ion-color-primary);\n      letter-spacing: 2px;\n      padding: 16px 8px;\n      span {\n        color: rgb(248, 179, 48);\n      }\n    }\n  }\n}\n.container {\n  background: #f1f4f6;\n  padding: 16px 32px 80px;\n  overflow-y: scroll;\n}\n\nstrong {\n  font-size: 1.25rem;\n  font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
