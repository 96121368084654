import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { WebBannerResponse } from '../model/banners.model';

@Injectable({
  providedIn: 'root'
})
export class StoreCollectionsService {

  constructor(private http: HttpClient) {}

  
  fetchCollection(data?: any):Observable<any> {
    return this.http.get(environment.base_url + environment.fetch_store_collection_method, {
      params: data,
    });
  }
  fetchBanner(data?: any): Observable<WebBannerResponse> {
    return this.http.get<WebBannerResponse>(
      environment.base_url + environment.fetch_banner_method,
      {
        params: data,
      }
    );
  }
}
