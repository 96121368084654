import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.scss'],
})
export class StoreCardComponent implements OnInit {
@Input() vendor:any
@Output () gotoDetails$ = new EventEmitter()
baseUrl = environment.base_url;

  constructor() {
    
   }

  ngOnInit() {}
  onStoreDetailsPage(vendor){
    this.gotoDetails$.emit(vendor)
  }
  onImageLoadError(e: any) {
    e.target.src = '../../assets/images/dummy/1.png';
  }
}
