// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 500px) {
  ion-app {
    background: rgba(0, 0, 0, 0.1);
  }
  ion-app::before {
    content: "";
    background: url('abstract-bg.png') no-repeat;
    background-size: cover;
    filter: blur(50px);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
}
@keyframes rotate-cw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE;IACE,8BAAA;EACF;EAAE;IACE,WAAA;IACA,4CAAA;IACA,sBAAA;IACA,kBAAA;IACA,kBAAA;IACA,WAAA;IACA,YAAA;IACA,OAAA;EAEJ;AACF;AA0BA;EACE;IAEE,oBAAA;EAdF;EAiBA;IAEE,yBAAA;EAfF;AACF","sourcesContent":["@media screen and (min-width: 500px) {\n  ion-app {\n    background: rgba(0, 0, 0, 0.1);\n    &::before {\n      content: \"\";\n      background: url(../assets/images/abstract-bg.png) no-repeat;\n      background-size: cover;\n      filter: blur(50px);\n      position: absolute;\n      width: 100%;\n      height: 100%;\n      left: 0;\n      //   -webkit-animation: rotate-cw 30s ease infinite both;\n      //   animation: rotate-cw 30s ease infinite both;\n    }\n  }\n\n  // ion-router-outlet {\n  //   max-width: 500px;\n  //   max-height: calc(100vh - 32px);\n  //   margin: auto;\n  //   border-radius: 8px;\n  //   border: 8px solid rgba(0, 0, 0, 0.5);\n  //   outline: 4px solid rgba(0, 0, 0, 0.9);\n  //   box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);\n  // }\n}\n\n@-webkit-keyframes rotate-cw {\n  0% {\n    -webkit-transform: rotate(0);\n    transform: rotate(0);\n    // transform: rotate(0) scale(2);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n    // transform: rotate(360deg) scale(2);\n  }\n}\n@keyframes rotate-cw {\n  0% {\n    -webkit-transform: rotate(0);\n    transform: rotate(0);\n    // transform: rotate(0) scale(2);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n    // transform: rotate(360deg) scale(2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
