import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BlogService } from '../core/services/blog.service';
import { Article } from '../core/model/blog.model';
import { environment } from 'src/environments/environment.prod';
import { BlogStore } from '../core/model/linked-store.model';
import { Vendor } from '../core/model/store-collection.model';
import { UiService } from '../ui.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
})
export class BlogDetailsComponent implements OnInit {
  showMenu = false;
  showResults = false;
  blogData: Article = {} as Article;
  popularBlogs: Article[] = [];
  strapiImageBaseUrl = environment.strapiImageBaseUrl;
  isModalOpen = false;
  shareUrl = '';

  blogRelation: BlogStore = {} as BlogStore;
  slug: string;
  constructor(
    private modalController: ModalController,
    private _route: ActivatedRoute,
    private _router: Router,
    private _ui: UiService,
    public _blogService: BlogService,
    private location: Location
  ) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.slug = params['slug'];
      if (this.slug) {
        this.onFetchBlog();
      }
    });
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  onFetchBlog() {
    const filterBySlug = {
      populate: '*',
      filters: {
        slug: this.slug,
      },
    };

    this._blogService
      .fetchBlogDetails(filterBySlug)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.blogData = res.data[0];
          this.onFetchRelation();
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          this.blogData.content = this.blogData?.content.replace(/\n/g, '<br>');
          if (this.blogData && this.blogData.category)
            this.onFetchRelatedBlog(this.blogData.category.id);
        }
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }
  formatContent(content: string): string {
    let formattedContent = content.replace(/\n\n/g, '<br><br>');

    const words = formattedContent.split(/\s+/);
    if (words.length > 70) {
      formattedContent = words.slice(0, 20).join(' ') + ' ...';
    }

    return formattedContent;
  }

  async openOtherBlog(data: Article) {
    this._router.navigate(['/blog/details', data.slug], {
      replaceUrl: true,
    });
  }

  onFetchRelatedBlog(categoryId: number) {
    const filterData: any = {
      populate: '*',
    };

    if (categoryId) {
      filterData.filters = {
        category: { id: categoryId },
      };
    }

    this._blogService
      .fetchBlogDetails(filterData)
      .then((res) => {
        this.popularBlogs = res.data.filter(
          (blog) => blog.id !== this.blogData.id
        );
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }
  onFetchRelation() {
    this._blogService
      .fetchBlogRelation({
        blogId: this.blogData.id,
      })
      .subscribe({
        next: (res) => {
          this.blogRelation = res.data[0];
        },
        error: (err) => {
          this._ui.presentToast(err);
        },
      });
  }
  openShareModal() {
    this.isModalOpen = true;

    const currentPath = this._router.url;
    this.shareUrl = `${window.location.origin}${currentPath}`;
  }
  dismissModal() {
    this.isModalOpen = false;
  }

  async onStoreDetailsPage(data: Vendor) {
    // this._router.navigate(['/store/details'], {
    //   queryParams: {
    //     storeName: data.vendorStoreName,
    //     code: data.vendorCode,
    //     // code: 'EI101',

    //     tableNumber: 0,
    //     diningAreaId: 0,
    //   },
    // });
    const storeName = data.vendorStoreName.replace(/\s+/g, '-');
    const url = `/store/details/${storeName}/${data.vendorCode}`;
    this._router.navigateByUrl(url);
  }
}
