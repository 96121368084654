import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
  fcmToken: string;
  orderStatusUpdated = new Subject<boolean>();

  constructor() {}

  // setToStorage(key, value) {
  //   return localStorage.setItem(key, value);
  // }

  getFromStorage(key) {
    // body...
    return localStorage.getItem(key);
  }

  async clearAllStorage() {
    // body...
    localStorage.clear();
    await Preferences.clear();
  }

  setCartStorage(cart) {
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  getCartFromStorage() {
    return JSON.parse(localStorage.getItem('cart'));
  }

  clearCartFromStorage() {
    localStorage.removeItem('cart');
  }

  setFcmToken(token: string) {
    this.fcmToken = token;
  }

  getFcmToken() {
    return this.fcmToken;
  }

  // async getAuthToken(): Promise<any> {
  //   const key = environment.token_key;
  //   const ret = await Storage.get({ key });
  //   return ret.value;
  // }

  async setObject(key: string, value: any) {
    await Preferences.set({ key, value: JSON.stringify(value) });
  }

  async getObject(key: string): Promise<any> {
    const ret = await Preferences.get({ key });
    return JSON.parse(ret.value);
  }

  async removeItem(key: string) {
    await Preferences.remove({ key });
  }

  async clear() {
    await Preferences.clear();
  }
}

export interface StorageInterface {
  CustomerNameKey?: any;
  UserMobileKey?: any;
  CustomerKey?: any;
  TokenKey?: any;
  UserStatusKey?: any;
  VendorStoreKey?: any;
  VendorKey?: any;
  VendorStatus?: any;
  VendorStoreName?: any;
  VendorStoreAddress?: any;
  CurrentTablePK?: any;
  CurrentTableNumber?: any;
  VendorUpiQR?: string;
  DiningAreaId: string;
  VendorCityIdKey?: number;
  IsDeliveryAvaialable?: boolean;
}

export enum StorageEnum {
  UserData = 'UserData',
}
