// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: #f9fcfb;
}

.top_action_bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);
}
.top_action_bar_container {
  display: flex;
  justify-content: center;
  padding: 12px 20px;
}
.top_action_bar .search_icon_wrapper,
.top_action_bar .scan_icon_wrapper {
  background-color: #fff;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.top_action_bar .search_icon_wrapper img,
.top_action_bar .scan_icon_wrapper img {
  width: 20px;
}
.top_action_bar .brand_name {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
}

main {
  padding: 60px 20px;
}
main ion-tabs ion-tab-bar {
  position: fixed;
  top: 44px;
  left: 0;
  right: 0;
}

.active-tab {
  color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/order/order.page.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;AAAF;;AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,gBAAA;EACA,UAAA;EACA,wCAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;AACJ;AAEE;;EAEE,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,oCAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ;AAEI;;EACE,WAAA;AACN;AAGE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;;AAKA;EACE,kBAAA;AAFF;AAKI;EACE,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AAHN;;AAQA;EACE,+BAAA;AALF","sourcesContent":["// New UI Styles Begins\nion-content {\n  --background: #f9fcfb;\n}\n.top_action_bar {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  background: #fff;\n  z-index: 9;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);\n\n  &_container {\n    display: flex;\n    justify-content: center;\n    padding: 12px 20px;\n  }\n\n  .search_icon_wrapper,\n  .scan_icon_wrapper {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 6px;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    // box-shadow: 0 0 8px rgba(0,0,0,0.025);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    img {\n      width: 20px;\n    }\n  }\n\n  .brand_name {\n    font-size: 16px;\n    letter-spacing: 2px;\n    font-weight: 500;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-left: 12px;\n  }\n}\n\nmain {\n  padding: 60px 20px;\n  ion-tabs {\n    // margin-top: 44px;\n    ion-tab-bar {\n      position: fixed;\n      top: 44px;\n      left: 0;\n      right: 0;\n    }\n  }\n}\n\n.active-tab {\n  color: var(--ion-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
