import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandler } from 'src/app/errorhandler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstaPostServices {

  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  fetchData(): Observable<any> {
    const url = `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,timestamp&limit=10&access_token=IGQWRNVmU0U3ljLTNiclVoblhYTi1KcGxGZA3FnLWxsYjN3YmZABcU1mMHA1a1dkc0g4a0xYMXVlQ2REOXUtV1pRc1I5ZAm5CNUswVHpZAam1iTGRORkNRcjdteU5YQnI5eG9SU0oySzE0OWpSa1Uxb081OFE4c01saDgZD`;
    return this.http.get<any>(url);
  }
}
