// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_menu {
  background-color: #fff;
  color: var(--ion-color-medium);
  box-shadow: 0 -12px 28px rgba(0, 0, 0, 0.09);
  font-size: 13px;
  font-weight: 400;
  z-index: 1;
}
.footer_menu ion-grid {
  padding: 0;
}
.footer_menu ion-grid ion-row ion-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}
.footer_menu ion-grid ion-row ion-col ion-icon {
  color: var(--ion-color-medium);
  font-size: 16px;
}
.footer_menu_active {
  color: var(--ion-color-primary);
  border-top: 3px solid var(--ion-color-primary);
}
.footer_menu_active ion-icon {
  color: var(--ion-color-primary) !important;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EAEA,8BAAA;EACA,4CAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;AAAF;AAKE;EACE,UAAA;AAHJ;AAKM;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,oBAAA;AAHR;AAKQ;EACE,8BAAA;EACA,eAAA;AAHV;AAQE;EACE,+BAAA;EAEA,8CAAA;AAPJ;AAQI;EACE,0CAAA;EAEA,eAAA;AAPN","sourcesContent":[".footer_menu {\n  background-color: #fff;\n  // color: #ABABAB;\n  color: var(--ion-color-medium);\n  box-shadow: 0 -12px 28px rgba(0, 0, 0, 0.09);\n  font-size: 13px;\n  font-weight: 400;\n  z-index: 1;\n  //   position: fixed;\n  //   bottom: 0;\n  //   left: 0;\n  //   right: 0;\n  ion-grid {\n    padding: 0;\n    ion-row {\n      ion-col {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-self: center;\n        // text-align: center;\n        ion-icon {\n          color: var(--ion-color-medium);\n          font-size: 16px;\n        }\n      }\n    }\n  }\n  &_active {\n    color: var(--ion-color-primary);\n    // color: var(--ion-color-dark);\n    border-top: 3px solid var(--ion-color-primary);\n    ion-icon {\n      color: var(--ion-color-primary) !important;\n      // color: var(--ion-color-dark) !important;\n      font-size: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
