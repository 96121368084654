// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card-subtitle {
  text-align: center;
}

ion-button {
  width: 250px;
  margin: 0 calc(50% - 125px);
}`, "",{"version":3,"sources":["webpack://./src/app/oops/oops.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,YAAA;EACA,2BAAA;AAEJ","sourcesContent":["ion-card-subtitle{\n    text-align: center;\n}\nion-button{\n    width: 250px;\n    margin: 0 calc(50% - 125px);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
