import { Component, OnInit } from '@angular/core';
import { QuickLinksServices } from '../../../core/services/quick-links.services';
import { QuickLinkData } from "../../../core/model/quick-links.model"

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {
  quicklinkdata: QuickLinkData = {} as QuickLinkData;  

  constructor(
    public _quickLinksService: QuickLinksServices,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this._quickLinksService.fetchData().subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.quicklinkdata = res.data;
        }
      },
      error: (error) => {
        console.error('Error fetching quick links data', error);
      },
    });
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
