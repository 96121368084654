// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: var(--ion-color-light);
}
ion-content .main_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
ion-content .main_wrapper .icon {
  text-align: center;
  margin: 0 auto;
  padding: 12px;
}
ion-content .main_wrapper .icon img {
  width: 60px;
}
ion-content .main_wrapper .info p {
  width: 100%;
  text-align: center;
}
ion-content .main_wrapper .info .title {
  font-size: 14px;
  margin-bottom: 0;
}
ion-content .main_wrapper .info .action {
  margin-top: 8px;
  font-size: 13px;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-content .main_wrapper .info .action ion-icon {
  padding-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/no-internet/no-internet.page.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,aAAA;AACR;AAAQ;EACI,kBAAA;EACA,cAAA;EACA,aAAA;AAEZ;AADY;EACI,WAAA;AAGhB;AACY;EACI,WAAA;EACA,kBAAA;AAChB;AACY;EACI,eAAA;EACA,gBAAA;AAChB;AACY;EACI,eAAA;EACA,eAAA;EACA,+BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAChB;AAAgB;EACI,iBAAA;AAEpB","sourcesContent":["ion-content{ \n    --background: var(--ion-color-light);\n\n    .main_wrapper {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n        height: 100vh;\n        .icon {\n            text-align: center;\n            margin: 0 auto;\n            padding: 12px;\n            img {\n                width: 60px;\n            }\n        }\n        .info {\n            p{\n                width: 100%;\n                text-align: center;\n            }\n            .title {\n                font-size: 14px;\n                margin-bottom: 0;\n            }\n            .action {\n                margin-top: 8px;\n                font-size: 13px;\n                color: var(--ion-color-primary);\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                ion-icon {\n                    padding-left: 4px;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
