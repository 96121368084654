export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDO_yBi5WUHZ5F8MIqrq_AQqSkAGK4qj_g',
    authDomain: 'easeinn-e0e04.firebaseapp.com',
    databaseURL: 'https://easeinn-e0e04.firebaseio.com',

    projectId: 'easeinn-e0e04',
    storageBucket: '',
    messagingSenderId: '48876910482',
    appId: '1:48876910482:web:267fa799198bec0ff6334b',
    vapidKey:
      'BIwaWgV-_nVbho64oZlh1NuVKDk2LwJSzAkWV32sYmMGGulMZM8S0H6h_5n-eqU3LC3l1yhuH4984akRcHGyx_4',
  },
  strapiBaseUrl: 'https://blogs.easeinn.app/api/',
  strapiImageBaseUrl: 'https://blogs.easeinn.app',
  strapiToken:
    'f2c6918dc78e21c926e3b4dddef348d9e01cb8ba7b47e7a8b8888e7387005e13ceedc9c9749d56b92ec5b7f28d889e9f6b0bdb51983d11e76cfedc2a8ac63945d731cb65a5e466dcfef20c2b938da791292ab2cc1a612fb3729037013ac2c0f32613552d0d23200bad6e3594e73bb70f2fa8d5f4f8f32334f12c6e485a9b6af8',
  // base_url: 'https://tapis.easeinn.app/',
  // base_url: 'https://e8bf-2401-4900-1c19-5397-a983-af46-d435-812.in.ngrok.io/',
  // base_url: 'https://apis.easeinn.app/',
  // bill_url: 'https://tapis.easeinn.app/mobile/orders/',
  bill_url: 'https://apis.easeinn.app/mobile/orders/',
  // base_url: 'https://easeinnapi.technomize.com/',
  base_url: 'https://apis.easeinn.app/',
  // base_url: 'https://tapis.easeinn.app/',

  // token_key: 'token',
  category_fetch: 'mobile/category/fetch',
  mobile_validate: 'mobile/addcustomer',
  // user_status_key: 'status',
  // user_mobile_key: 'mobile',
  item_fetch: 'mobile/item/fetchbycategory',
  cart_detail: 'cart',
  fetch_cart: 'mobile/item/fetchforcart',
  // vendor_key: 'vendor_id',
  // customer_key: 'customer_id',
  vendor_fetch: 'vendor/fetchdetail',
  // vendor_status: 'vendor_status',
  // vendor_store_name: 'vendor_store_name',
  otp_send: 'mobile/sendotpcustomer',
  edit_customer: 'mobile/editcustomer',
  store_fetch: 'vendor/fetch',
  all_store: 'vendor/all-stores',
  store_search: 'vendor/guest/store-search',
  locality_fetch: 'locality/fetch',
  faqs_fetch: 'faqs/fetch',
  quicklinks_fetch: 'quick_links/quickLinkWithCategories',
  promotional_banners: 'banners/promotional_banners',

  // vendor_store_key: 'vendor_key',
  // vendor_store_address: 'vendor_address',
  is_otp_send: 'otp_status',
  // current_table_num: 'table_num',
  // current_table_pk: 'table_id',
  check_running_order: 'mobile/order/checkrunningstatus',
  update_profile_method: 'mobile/editcustomer',
  appVersion: '0.0.1',
  fetch_menu_api: 'product/listMenu',
  fetch_item_by_ids_api: 'product/fetchItemByIds',
  fetch_add_ons: 'product/fetchAddons',
  fetch_cart_upsells: 'product/fetchUpSellForCart',
  fetch_coupons: 'mobile/coupons',
  fetch_store_meta_tags: 'vendor/fetch/meta/tags/1',
  create_address_api: 'mobile/customerAddress',
  fetch_address_api: 'mobile/customerAddress',
  update_address_api: 'mobile/customerAddress/${id}',
  delete_acc_req_api: 'delete-acc-req/add',
  banner_api: 'banners/promotional_banners',
  fetch_store_collection_method: 'store_collection/fetch',
  fetch_linked_store_method: 'blog_store/fetch',
  create_enquiry: 'enquiry/create',
  fetch_banner_method: 'banners/web_banners',
  vendor_onboarding: 'vendor/onboarding',
  fetch_city_method: 'location/city',
  fetch_area_method: 'location/area',
  fetch_states_method: 'location/state',
  fetch_store_cuisines_method: 'store_cuisines/fetch',
};
