import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { Stores } from 'src/app/core/model/all-store.modal';
import { Locations } from 'src/app/core/model/location.model';
import { StoreListService } from 'src/app/core/services/store.service';
import { UiService } from 'src/app/ui.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit {
  showMenu = false;
  showResults = false;
  showLocalityResults = false;
  searchFormControl = new FormControl(null);
  allStoresData: Stores[] = [];
  allLocalityData: Locations[] = [];
  @Input() isFocusMode: boolean = false;
  baseUrl = environment.base_url;
  searchLocalityFormControl = new FormControl(null);
  @ViewChild('locationInput') locationInput!: ElementRef<HTMLInputElement>; // ViewChild to access input element
  constructor(
    private _store: StoreListService,
    private _uiService: UiService,
    private _router: Router,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isFocusMode'] && this.isFocusMode) {
      this.setFocus();
    }
  }

  setFocus(): void {
    if (this.locationInput) {
      this.locationInput.nativeElement.focus(); // Programmatically focus the input
    }
  }
  ngOnInit() {
    this.loadSearchFilter();
    this.loadLocalitySearchFilter();
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  loadSearchFilter() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res) => {
          this.onGet(res);
        })
      )
      .subscribe();
  }
  loadLocalitySearchFilter() {
    this.searchLocalityFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res) => {
          this.onGetLocality(res);
        })
      )
      .subscribe();
  }
  onGet(searchData) {
    this._store
      .fetchStoreSearch({
        ...(searchData && { searchData: searchData }),
        // vendorStatus: 'ACTIVE',
        // currentPage: 1,
        // itemsPerPage: 5,

        // expirationDateStart: '',
        // expirationDateEnd: '',
      })
      .subscribe({
        next: (res) => {
          this.allStoresData = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }
  onGetLocality(searchData) {
    this._store
      .fetchLocality({
        page: 1,
        limit: 30,
        ...(searchData && { search: searchData }),
      })
      .subscribe({
        next: (res) => {
          this.allLocalityData = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }
  async onStoreDetailsPage(data: Stores) {
    this.cdr.detectChanges();
    const storeName = data.vendorStoreName.replace(/\s+/g, '-');
    const url = `/store/details/${storeName}/${data.vendorCode}`;
    this._router.navigateByUrl(url);
  }
  async onStore(data: Locations) {
    this.cdr.detectChanges();
    let url: any;
    const areaName = data.area ? data.area.replace(/\s+/g, '-') : null;
    const cityName = data.city ? data.city : null;
    const areaId = data.areaId ? data.areaId : null;
    const cityId = data.cityId ? data.cityId : null;
    if (areaName && areaName !== null) {
      url = `/all-store/${areaName}-${cityName}/${areaId}`;
    } else {
      url = `/all-storess/${cityName}/${cityId}`;
    }
    this._router.navigateByUrl(url);
  }

  hideSearchResultsWithDelay() {
    setTimeout(() => {
      this.showResults = false;
    }, 200);
  }
  hideLocalityResultsWithDelay() {
    setTimeout(() => {
      this.showLocalityResults = false;
    }, 300);
  }
}
