import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'src/app/errorhandler.service';
import { environment } from 'src/environments/environment.prod';
import { BlogStoreResponse } from '../model/linked-store.model';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  async fetchBlogDetails(PathData?: any): Promise<any> {
    const queryString = PathData ? '?' + this.buildFilterQuery(PathData) : '';
    try {
      const response = await fetch(
        `${environment.strapiBaseUrl}blogs${queryString}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${environment.strapiToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  }

  private buildFilterQuery(filters: any): string {
    const query = [];

    const addQuery = (data: any, prefix = '') => {
      for (const key in data) {
        const value = data[key];
        if (value !== null && typeof value === 'object') {
          addQuery(value, prefix ? `${prefix}[${key}]` : key);
        } else if (value !== undefined) {
          query.push(
            `${encodeURIComponent(
              prefix ? `${prefix}[${key}]` : key
            )}=${encodeURIComponent(value)}`
          );
        }
      }
    };
    if (filters.searchText) {
      query.push(
        `filters[Title][$containsi]=${encodeURIComponent(filters.searchText)}`
      );
    }
    addQuery(filters);
    return query.join('&');
  }

  async fetchBlogData(PathData?: any): Promise<any> {
    const queryString = PathData
      ? '?' + new URLSearchParams(Object.entries(PathData)).toString()
      : '';
    try {
      const response = await fetch(
        `${environment.strapiBaseUrl}${'blogs'}${queryString}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${environment.strapiToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  }

  async fetchCategoryData(PathData?: any): Promise<any> {
    const queryString = PathData
      ? '?' + new URLSearchParams(Object.entries(PathData)).toString()
      : '';
    try {
      const response = await fetch(
        `${environment.strapiBaseUrl}${'categories'}${queryString}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${environment.strapiToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  }
  createEnquiry(body: any): Observable<any> {
    return this.http.post(
      environment.base_url + environment.create_enquiry,
      body
    );
  }
  async fetchStoreDetail(vendorDetails?: any): Promise<any> {
    const queryString = vendorDetails
      ? '?' + new URLSearchParams(Object.entries(vendorDetails)).toString()
      : '';
    try {
      const response = await fetch(
        `${environment.base_url}${environment.vendor_fetch}${queryString}`,
        {
          method: 'GET',
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  }

  fetchFaqs(param: any): Observable<any> {
    const url = `${environment.base_url}${environment.faqs_fetch}`;
    return this.http.get(url, {
      params: param,
    });
  }

  fetchBlogRelation(data?: any): Observable<BlogStoreResponse> {
    return this.http.get<BlogStoreResponse>(
      environment.base_url + environment.fetch_linked_store_method,
      {
        params: data,
      }
    );
  }

  // async  fetchBlogRelation(vendorDetails?: any): Promise<any>{

  //   const queryString = vendorDetails ? '?' + this.buildFilterQuery(vendorDetails) : '';
  //   try {
  //     const response = await fetch(
  //       `${environment.base_url}${environment.fetch_linked_store_method}${queryString}`,
  //       {
  //         method: 'GET',

  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error('Fetch error:', error);
  //     return null;
  //   }

  // }
}
