// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  position: relative;
  padding: 4px;
}
.hero img {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid var(--ion-color-light);
}
@media screen and (max-width: 768px) {
  .hero {
    padding: 20px;
  }
}
.hero__header {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
}
.hero__header--title {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .hero__header--title {
    font-size: 36px;
    margin-top: 32px;
  }
}
.hero__header--subtitle {
  font-size: 20px;
  color: var(--ion-color-medium);
  margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .hero__header--subtitle {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/insta-posts/insta-posts.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;AAAE;EAEE,iBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,wCAAA;AACJ;AACE;EAXF;IAYI,aAAA;EAEF;AACF;AADE;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,8BAAA;EACA,2EAAA;AAGJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAAN;AACM;EAJF;IAKI,eAAA;IACA,gBAAA;EAEN;AACF;AAAI;EACE,eAAA;EACA,8BAAA;EACA,gBAAA;AAEN;AADM;EAJF;IAKI,eAAA;EAIN;AACF","sourcesContent":[".hero {\n  position: relative;\n  padding: 4px;\n  img {\n    // height: 400px;\n    max-height: 400px;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 8px;\n    border: 1px solid var(--ion-color-light);\n  }\n  @media screen and (max-width: 768px) {\n    padding: 20px;\n  }\n  &__header {\n    text-align: center;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    padding: 16px;\n    background: rgb(0, 0, 0, 0.1);\n    background: linear-gradient(\n      180deg,\n      rgba(0, 0, 0, 0) 0%,\n      rgba(0, 0, 0, 1) 100%\n    );\n    &--title {\n      font-size: 48px;\n      font-weight: 700;\n      color: #fff;\n      @media screen and (max-width: 768px) {\n        font-size: 36px;\n        margin-top: 32px;\n      }\n    }\n    &--subtitle {\n      font-size: 20px;\n      color: var(--ion-color-medium);\n      margin-top: 16px;\n      @media screen and (max-width: 768px) {\n        font-size: 16px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
