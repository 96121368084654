// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  padding: 20px 20px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main .media_wrapper {
  padding: 24px 24px 40px;
  text-align: center;
}
.main .media_wrapper img {
  width: 160px;
}
.main .info_wrapper {
  text-align: center;
}
.main .info_wrapper .title {
  font-size: 24px;
  font-weight: 500;
  color: var(--ion-color-dark);
  margin: 0;
}
.main .info_wrapper .subtitle {
  font-size: 13px;
  color: #898A8F;
}
.main .action_wrapper {
  position: absolute;
  bottom: 48px;
  left: 24px;
  right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/welcome-screen/welcome-screen.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AACJ;AAAI;EACI,uBAAA;EACA,kBAAA;AAER;AADQ;EACI,YAAA;AAGZ;AAAI;EACI,kBAAA;AAER;AADQ;EACI,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,SAAA;AAGZ;AADQ;EACI,eAAA;EACA,cAAA;AAGZ;AAAI;EACI,kBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;AAER","sourcesContent":[".main {\n    padding: 20px 20px;\n    height: 80vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    .media_wrapper {\n        padding: 24px 24px 40px;\n        text-align: center;\n        img {\n            width: 160px;\n        }\n    }\n    .info_wrapper{\n        text-align: center;\n        .title {\n            font-size: 24px;\n            font-weight: 500;\n            color: var(--ion-color-dark);\n            margin: 0;\n        }\n        .subtitle {\n            font-size: 13px;\n            color: #898A8F;\n        }\n    }\n    .action_wrapper {\n        position: absolute;\n        bottom: 48px;\n        left: 24px;\n        right: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
