import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorServices } from '../core/services/vendor.services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-vendor-onboarding',
  templateUrl: './vendor-onboarding.page.html',
  styleUrls: ['./vendor-onboarding.page.scss'],
})
export class VendorOnboardingPage {
  vendorData = {
    vendorName: '',
    vendorStoreName: '',
    vendorStoreType: '',
    vendorLocation: '',
    userLoginId: '',
    password: '',
    type: '',
    mobile: '',
    vendorAppType: '',
    vendorLat: '',
    vendorLang: '',
    storeTiming: '',
    alternateMobile: '',
    email: '',
    printWidth: '',
    serviceType: '',
    deliveryDistance: null,
    storeIcon: null,
  };

  types = ['Type A', 'Type B', 'Type C'];
  serviceTypes = ['DELIVERY', 'TAKEAWAY', 'ALL'];
  appTypes = ['Mobile App', 'Web App', 'Hybrid App'];

  vendorCreateObs!: Observable<any>;
  cities$: Observable<any>;
  cities: any[] = [];
  states$: Observable<any>;
  states: any[] = [];
  areas$: Observable<any>;
  areas: any[] = [];

  constructor(private _vendorServices: VendorServices) {}

  ngOnInit() {
    this.fetchStates();
  }

  fetchStates() {
    this.states$ = this._vendorServices.fetchStates();
    this.states$.subscribe((res) => {
      this.states = res.data;
      if (this.states.length) {
        this.fetchCities(this.states[0].StateIdPK);
      }
    });
  }
  fetchCities(stateId: number) {
    this.cities$ = this._vendorServices.fetchCity(stateId);
    this.cities$.subscribe((res) => {
      this.cities = res.data;
      if (this.states.length) {
        this.fetchAreas(this.cities[0].cityIdPK);
      }
    });
  }
  fetchAreas(cityId: number) {
    this.areas$ = this._vendorServices.fetchArea(cityId);

    this.areas$.subscribe((res) => {
      this.areas = res.data;
    });
  }

  onChangeState(event) {
    if (event.detail.value) {
      this.fetchCities(+event.detail.value);
    }
  }

  onChangeCity(event) {
    if (event.detail.value) {
      this.fetchAreas(+event.detail.value);
    }
  }

  onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      console.log('Selected file:', file);
      this.vendorData.storeIcon = file;
    }
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const formData = new FormData();
      
      Object.keys(this.vendorData).forEach((key) => {
        if (key !== 'storeIcon') {
          formData.append(key, this.vendorData[key]);
        }
      });
  
      if (this.vendorData.storeIcon) {
        formData.append('image', this.vendorData.storeIcon);
      }
  
      formData.forEach((value, key) => {
        console.log(key, value);
      });
  
      this.vendorCreateObs = this._vendorServices.createVendor(formData);
  
      this.vendorCreateObs.subscribe({
        next: (responseData) => {
          console.log('Response:', responseData);
          if (responseData?.body?.statusCode === 200) {
            console.log('Vendor created successfully.');
          }
        },
        error: (error) => {
          console.error('Error occurred while creating vendor:', error);
        },
      });
    }
  }
}
